
import { defineComponent } from "vue";
import moment from "moment";
import DateSelector from "../../../components/chart/DateSelector.vue";
const Compression = require('../../../modules/app/compression'); 


export default defineComponent({
  name: "APIEditor" ,
    components:  { DateSelector },
  data (){
      
      return {
 savedApiKey : false, 
          setting  : {
           
            id : "" , 
            path : "",
            dateOfExpiry : "IndefinitePeriod" ,  
            sensorIDs:[],
            RequestAllAtOnce : false,
            needFirstData :false ,
            needAggregateData : true,
            needRawData : false ,
            needLatestData : false 
            },
            viewTemplate : {
            src:"/pwa/#/chart/metric/?start={START_DATE}&end={END_DATE}&particle_size={PARTICLE_SIZE}&agg={AGGREGATE}",
          },
          view : { src : ""}
      }
  },
   mounted : async function (this:any){
       
           this.$emit('show-loader',true);   
        
        this.$emit('show-header');  
      
        this.Sql = await  ( window as any ).getSql();
       
        if(this.$route.query ){
          if(this.$route.query.id ){
          
            const res =  await fetch( process.env.VUE_APP_APP_API_V4_URL  + "customapi/"+ this.$route.query.id + "/"  ) ;
          
            const data = await res.json();
            this.setting = data ; 
            this.$route.query.sql = data.sql  ; 

            let params = new URLSearchParams(this.viewTemplate.src);
            params.append('q', window.encodeURIComponent (data.sql));

            this.viewTemplate.src = this.viewTemplate.src+ '&' + params.toString();

          
        this.updateData();
        }
      }
      this.$emit('show-loader',false);
  },
  methods : {
    
    async apikeyToClipboard(this:any ){

      try {
        await navigator.clipboard.writeText(this.$refs.apikey.value);
         this.$emit( 'show-flash', {"message":"クリップボードにコピーしました。","type": "success"});
    } catch (err) {
       this.$emit( 'show-flash', {"message":"クリップボードにコピーできませんでした。","type": "warrning"});
    }
     

    },
    getMoment (){
       return moment();
    },
    async deleteSetting ( this:any ){


        if ( !window.confirm( "このAPIを本当に削除しますか？" )){
          return;
        }
        const res= await fetch( 
          process.env.VUE_APP_APP_API_V4_URL + "customapi/" +  this.$route.query.id + "/" , 
          { 
            method: "DELETE", 
          }) ;
      
      const json = await res.json();
      
      if( json.success ){

         this.$emit( 'show-flash', {"message":"削除しました。","type": "success"});
        this.$router.push('/api/designer/');
      }else {

         this.$emit( 'show-flash', {"message":"削除に失敗しました。","type": "warning"});
      }

    },
    async saveSetting( this:any ){

      const searchParams = new URLSearchParams(window.location.search)

      if( !searchParams.has('ec') ){

           this.$emit( 'show-flash', {"message":"SQLを設定してください。","type": "warning"});
           return;
      }

      var sql =  await Compression.Compression.decompressString( searchParams.get('ec'));  //=> true
       
      const data =Object.assign({}, this.setting );

      if (data.path == "" ){

           this.$emit( 'show-flash', {"message":"APIのパスを設定して下さい","type": "warning"});
           return;
      }

      if( false == Array.isArray(data.sensorIDs) ){

           data.sensorIDs =  data.sensorIDs.split(","); 
      }
      
      data .sql = sql ;
      
      const res= await fetch( 
          process.env.VUE_APP_APP_API_V4_URL + "customapi/" , 
          { 
            method: "POST", 
            headers: {
			        
              'Content-Type': 'application/json' 
		        },
      		  body: JSON.stringify(data)
          }) ;
      
      const json = await res.json();
      
      if( json.success ){

         this.$emit( 'show-flash', {"message":"更新しました。","type": "success"});

        if( json.apiKey ){

          this.savedApiKey = json.apiKey ;
          // json.apiKey
        }
      }
      if(json.error){

        this.$emit( 'show-flash', {"message": json.error ,"type": "warning"}); 
      }
      
    },
    replacePathParam( this:any, p:string ){
    
      var path = p ; // str copy 
//https://192.168.5.131:1024/api/v2/devices/00055405/data?start=2022-05-18&end=2022-05-19&agg=median&continuationToken=undefin

        path = path.replaceAll("{START_DATE}", this.$refs.dateselector.getStartDate() );
        path = path.replaceAll("{END_DATE}",this.$refs.dateselector.getEndDate());   
        path = path.replaceAll("{PARTICLE_SIZE}",  this.$refs.dateselector.getParticleSize() );
        path = path.replaceAll("{TEMPORAL_GRANULARITY}",  this.$refs.dateselector.getParticleSize() );
        path = path.replaceAll("{AGGREGATE}",  this.$refs.dateselector.getAggregate() );   
        path = path.replaceAll("{TODAY}",  moment().format('YYYY-MM-DD') );   
        path = path.replaceAll("{TODAY+1D}", moment().add('d',1).format('YYYY-MM-DD') );   
  
        return path;
    },

     
     async updateData(this:any ){

         this.$emit('show-loader',true);

        if (this.setting.sensorIDs !== undefined)
        if( false == Array.isArray(this.setting.sensorIDs) ){
 
          
           this.setting.sensorIDs =  this.setting.sensorIDs.split(","); 
           
        }
         
             
         const resp = await this.$refs.dateselector.fetchData(  

                  this.setting.sensorIDs ,
                  
                  this.setting.RequestAllAtOnce,
               
                  this.setting.needFirstData,
                  this.setting.needAggregateData,
                  this.setting.needRawData ,
                   this.setting.needLatestData
          );
        
        if (resp  && resp.flash){
         
                this.$emit( 'show-flash', resp.flash);
                this.$emit('show-loader',false);  
          }
          this.view.src = this.replacePathParam( this.viewTemplate.src ).replaceAll ( '%','%25' ).replaceAll ( '+','%2B' )  + "&iFrameId=" + moment().unix()  ;
        
       // https://iotcore-inte-api.azurewebsites.net/api/AddMenu?code=fkcoPR3MPBwkBi_LdiIBsr9QyO1wr39X-_ZZNkrwhpetAzFuyJNmUQ==
    }   
  }
});
